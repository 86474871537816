import MainMenu from './MainMenu'
import Footer from './Footer'

import nosotros from '../images/nosotros/img-top-nosotros.png'
/* Valores icons */
import integridad from '../images/nosotros/valores-icono-integridad.png'
import crecimiento from '../images/nosotros/valores-icono-crecimiento-personal.png'
import competitividad from '../images/nosotros/valores-icono-competitividad.png'
import calidad from '../images/nosotros/valores-icono-calidad.png'
import trabajo_equipo from '../images/nosotros/valores-icono-trabajo-en-equipo.png'
import honestidad from '../images/nosotros/valores-icono-honestidad.png'
import congruencia from '../images/nosotros/valores-icono-congruencia.png'
import politica from '../images/nosotros/valores-icono-politica.png'
/* Valores */
const valores = [
    { icon: integridad, title: 'Integridad', description: 'Actuar siempre de forma ética. Nos aseguramos de ser responsables y disciplinados en nuestras labores diarias, además de dirigirnos con honradez y respeto, hacia nosotros mismos y con nuestros clientes.' },
    { icon: crecimiento, title: 'Crecimiento Personal', description: 'Actualizar continuamente nuestros conocimientos y habilidades. Fomentar el autoconocimiento para ser asertivos en el discernimiento de lo prioritario sobre lo secundario.' },
    { icon: competitividad, title: 'Competitividad', description: 'Ofrecer soluciones orientadas a la eficiencia y eficacia con el objetivo de ser excelentes en nuestros procesos.' },
    { icon: calidad, title: 'Calidad', description: 'Enfocar los esfuerzos en hacer las cosas bien para satisfacer las necesidades de nuestros clientes y estar en constante búsqueda de una mejora continua para que todos los integrantes de la organización se vean beneficiados.' },
    { icon: trabajo_equipo, title: 'Trabajo en equipo', description: 'El apoyo de unos a otros dentro de la organización es esencial para lograr los objetivos propuestos y generar soluciones que satisfagan las necesidades de nuestros clientes.' },
    { icon: honestidad, title: 'Honestidad', description: 'Hablar siempre con la verdad, ser claros y plantear objetivamente nuestras posibilidades y límites.' },
    { icon: congruencia, title: 'Congruencia', description: 'Actuar conforme a los valores y procedimientos establecidos.' },
    { icon: politica, title: 'Política de Calidad', description: 'Nuestra Política de Calidad está basada en los criterios de la norma ISO 9001:2015' }
]

const Nosotros = () => (
    <div>
        <div className="uk-container-expand header">
            <MainMenu />
        </div>
        <div className="uk-container-expand uk-margin-remove uk-padding-remove uk-background-muted">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-margin-top" data-uk-grid>
                    <div className="uk-text-center">
                        <img src={nosotros} data-uk-responsive alt=""/>
                    </div>
                    <div className="uk-width-1-2 uk-margin-auto uk-margin-auto-vertical">
                        <h3 className='uk-margin-remove ffx-heading'>IMPRESI&Oacute;N EN FLEXOGRAF&Iacute;A Y DIGITAL</h3>
                        <h1 className='uk-margin-remove h3-gray ffx-bold'>EMPRESA 100% MEXICANA FUNDADA EN EL 2001</h1>
                        <hr />
                        <p className='uk-margin-remove h3-gray'>Nuestro principal objetivo es satisfacer a nuestros clientes mediante un servicio eficiente y de calidad. Todo nuestro personal tiene la experiencia y los conocimientos necesarios para desarrollar un proceso de manufactura con la calidad y rapidez que nuestros clientes merecen.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="uk-container-expand uk-margin-remove uk-padding-remove uk-background-default">
            <div className='uk-container'>
                <div className="uk-child-width-expand@s" data-uk-grid>
                    <div>
                        <div className="uk-card uk-card-body">
                            <h1 className='uk-margin-remove h3-gray ffx-bold uk-text-uppercase'>Misi&oacute;n</h1>
                            <hr />
                            <p className='uk-margin-remove h3-gray'>Brindar soluciones especializadas en la manufactura de etiquetas y productos de empaque que proyecten efectivamente la identidad del cliente, trabajando con la mejor calidad y apegándonos a sus requerimientos.</p>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-body">
                            <h1 className='uk-margin-remove h3-gray ffx-bold uk-text-uppercase'>Visi&oacute;n</h1>
                            <hr />
                            <p className='uk-margin-remove h3-gray'>Ser los líderes en el mercado y reconocidos por nuestros clientes como una empresa moderna que busca la innovación y brindar el mejor servicio mediante la aplicación de tecnología de punta y un personal 100% capacitado.</p>
                        </div>
                    </div>
                </div>
                <div className="uk-child-width-expand@s uk-margin-small-top" data-uk-grid>
                    <div>
                        <div className="uk-card uk-card-body">
                            <h1 className='uk-margin-remove h3-gray ffx-bold uk-text-uppercase'>Valores</h1>
                            <hr />
                            <div className="uk-grid-collapse uk-child-width-1-2@s uk-margin-remove" data-uk-grid>
                                <Valores list={valores} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
);

function Valores(props) {
    const items = props.list.map((item, i) =>
        <div key={i}>
            <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                <div className="uk-width-auto">
                    <img src={item.icon} alt=""/>
                </div>
                <div className="uk-width-expand uk-text-justify uk-margin uk-padding-small">
                    <h5 className="uk-text-uppercase uk-text-bold uk-margin-remove-bottom">{item.title}</h5>
                    <p className="uk-text-meta uk-margin-remove-top">{item.description}</p>
                </div>
            </div>
        </div>
    )
    return items
}

export default Nosotros;