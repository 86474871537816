import cmd from '../images/footer/cmd-clientes-index.jpg'
import pisa from '../images/footer/pisa-clientes-index.jpg'
import pueblo_viejo from '../images/footer/pueblo-viejo-clientes-index.jpg'
import canoil from '../images/footer/canoil-clientes-index.jpg'

const Footer = () => (
    <>
        <div className="uk-container-expand footer-top-section uk-text-left">
            <div className='uk-container uk-padding-small'>
                <div className="uk-light" data-uk-grid>
                    <div className="uk-width-1-4@m">
                        <div>
                            <ul className="uk-nav uk-nav-default">
                                <li className="uk-nav-header">soluciones Etiquetas</li>
                                <li className="uk-nav-divider"></li>
                                <li><a href="/etiquetas-autoadheribles">Etiquetas autoadheribles</a></li>
                                <li><a href="/empaques-flexibles">Empaques flexibles</a></li>
                                <li><a href="/mangas-thermoencogibles">Mangas thermoencogibles</a></li>
                                <li><a href="/boletos-para-eventos">Boletos para eventos</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="uk-width-expand@m">
                        <div>
                            <ul className="uk-nav uk-nav-default">
                                <li className="uk-nav-header">Industrias</li>
                                <li className="uk-nav-divider"></li>
                                <li>
                                    <div className="uk-child-width-1-2@m uk-light" data-uk-grid>
                                        <div>
                                            <ul className="uk-nav uk-nav-default">
                                                <li><a href="/industrias">Farmaceutica y Veterinaria</a></li>
                                                <li><a href="/industrias">Alimentos y bebidas</a></li>
                                                <li><a href="/industrias">Vinos y licores</a></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul className="uk-nav uk-nav-default">
                                                <li><a href="/industrias">Industrial y cuidado del hogar</a></li>
                                                <li><a href="/industrias">Cuidado personal</a></li>
                                                <li><a href="/industrias">Automotriz</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="uk-width-1-4@m">
                        <div>
                            <ul className="uk-nav uk-nav-default">
                                <li className="uk-nav-header">Informaci&oacute;n</li>
                                <li className="uk-nav-divider"></li>
                                <li><a href="/nosotros">Nosotros</a></li>
                                <li><a href="/tecnologia">Tecnolog&iacute;a</a></li>
                                <li><a href="/contacto">Contacto</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className='uk-container-expand'>
            <div className='uk-container'>
                <div className="uk-text-center" data-uk-grid>
                    <div className="uk-width-1-4@m uk-padding-small">
                        <div className="uk-card uk-card-body uk-card-ffx">ALGUNOS DE NUESTROS CLIENTES</div>
                    </div>
                    <div className="uk-width-expand@m uk-padding-small">
                        <div>
                            <img src={cmd} alt=""/>
                            <img src={pisa} alt=""/>
                            <img src={pueblo_viejo} alt=""/>
                            <img src={canoil} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='uk-container-expand footer'>
            <div className="uk-container uk-text-white uk-text-center uk-padding-small uk-text-small">
                <p>&reg; FORMFLEX | Soluciones de impresi&oacute;n en flexograf&iacute;a y digital | 2022</p>
            </div>
        </div>
    </>
);

export default Footer;