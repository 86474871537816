import MainMenu from './MainMenu'
import Footer from './Footer'

// Icons
import ubicacion from '../images/contacto/icono-direccion-contacto.png'
import telefono from '../images/contacto/icono-telefono-contacto.png'
import correo from '../images/contacto/icono-correo-contacto.png'

const Contacto = () => (
    <div>
        <div className="uk-container-expand header">
            <MainMenu />
        </div>
        <div className="uk-container-expand uk-background-muted contactoHeader">
            <div className='uk-container'>
                <div className='uk-width-1-1 uk-margin-small-top'>
                    <div className="uk-child-width-expand@s uk-text-center uk-align-right" data-uk-grid>
                        <div className="uk-card uk-card-body uk-width-2-3 uk-align-right">
                            <h3 className='uk-margin-remove h3-gray'>NOS INTERESA CONOCER</h3>
                            <h1 className='uk-margin-remove ffx-heading ffx-bold'>TU PROYECTO</h1>
                            <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                                <div className="uk-width-auto">
                                    <img src={ubicacion} alt=""/>
                                </div>
                                <div className="uk-width-expand uk-text-justify uk-margin">
                                    <p className=" uk-margin-remove-top">Periférico Poniente 7301 Col. Vallarta Parque Industrial, Zapopan, Jalisco. C.P. 45010</p>
                                </div>
                            </div>
                            <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                                <div className="uk-width-auto">
                                    <img src={telefono} alt=""/>
                                </div>
                                <div className="uk-width-expand uk-text-justify uk-margin">
                                    <p className=" uk-margin-remove-top">01 33 3777 1632</p>
                                </div>
                            </div>
                            <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                                <div className="uk-width-auto">
                                    <img src={correo} alt=""/>
                                </div>
                                <div className="uk-width-expand uk-text-justify uk-margin">
                                    <p className=" uk-margin-remove-top">ventas@formflex.com.mx</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="uk-container-expand uk-background-muted contactoHeader">
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-remove uk-visible@s">
                <iframe title="web" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7465.3723416652665!2d-103.456044!3d20.682343!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2c507a866fd328a8!2sFormflex%2C%20S.A.%20De%20C.V.!5e0!3m2!1ses-419!2smx!4v1653439405719!5m2!1ses-419!2smx"  width="100%" height="450" frameBorder="0" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <Footer />
    </div>
);


export default Contacto;