import MainMenu from './MainMenu'
import Footer from './Footer'
//Agroquimica
import agroquimica_1 from '../images/industrias/agroquimica/industrias-agroquimica-1.png'
import agroquimica_2 from '../images/industrias/agroquimica/industrias-agroquimica-2.png'
// Alimentos y bebidas
import alimentos_1 from '../images/industrias/alimentos-bebidas/industrias-alimentos-1.png'
import alimentos_2 from '../images/industrias/alimentos-bebidas/industrias-alimentos-2.png'
import alimentos_3 from '../images/industrias/alimentos-bebidas/industrias-alimentos-3.png'
import alimentos_4 from '../images/industrias/alimentos-bebidas/industrias-alimentos-4.png'
import alimentos_5 from '../images/industrias/alimentos-bebidas/industrias-alimentos-5.png'
import alimentos_6 from '../images/industrias/alimentos-bebidas/industrias-alimentos-6.png'
import alimentos_7 from '../images/industrias/alimentos-bebidas/industrias-alimentos-7.png'
// Automotriz
import automotriz_1 from '../images/industrias/automotriz/industrias-automotriz-1.png'
import automotriz_2 from '../images/industrias/automotriz/industrias-automotriz-2.png'
import automotriz_3 from '../images/industrias/automotriz/industrias-automotriz-3.png'
import automotriz_4 from '../images/industrias/automotriz/industrias-automotriz-4.png'
// Vinos y licores
import vinos_1 from '../images/industrias/cervecera-tequilera/industrias-cervecera-1.png'
import vinos_2 from '../images/industrias/cervecera-tequilera/industrias-cervecera-2.png'
import vinos_3 from '../images/industrias/cervecera-tequilera/industrias-cervecera-3.png'
import vinos_4 from '../images/industrias/cervecera-tequilera/industrias-cervecera-4.png'
import vinos_5 from '../images/industrias/cervecera-tequilera/industrias-cervecera-5.png'
import vinos_6 from '../images/industrias/cervecera-tequilera/industrias-cervecera-6.png'
// Farmaceutica
import farmaceutica_1 from '../images/industrias/farmaceutica-veterinaria/industrias-farmaceutica-1.png'
import farmaceutica_2 from '../images/industrias/farmaceutica-veterinaria/industrias-farmaceutica-2.png'
import farmaceutica_3 from '../images/industrias/farmaceutica-veterinaria/industrias-farmaceutica-3.png'
import farmaceutica_4 from '../images/industrias/farmaceutica-veterinaria/industrias-farmaceutica-4.png'
import farmaceutica_5 from '../images/industrias/farmaceutica-veterinaria/industrias-farmaceutica-5.png'
import farmaceutica_6 from '../images/industrias/farmaceutica-veterinaria/industrias-farmaceutica-6.png'
// Cuidado personal
import cuidado_1 from '../images/industrias/salud-cosmetica/industrias-salud-1.png'
import cuidado_2 from '../images/industrias/salud-cosmetica/industrias-salud-2.png'
import cuidado_3 from '../images/industrias/salud-cosmetica/industrias-salud-3.png'
import cuidado_4 from '../images/industrias/salud-cosmetica/industrias-salud-4.png'
// Galerias
const agroquimica = [{image: agroquimica_1}, {image: agroquimica_2}]
const alimentos = [{image: alimentos_1}, {image: alimentos_2}, {image: alimentos_3}, {image: alimentos_4}, {image: alimentos_5}, {image: alimentos_6}, {image: alimentos_7}]
const automotriz = [{image: automotriz_1}, {image: automotriz_2}, {image: automotriz_3}, {image: automotriz_4}]
const vinos = [{image: vinos_1}, {image: vinos_2}, {image: vinos_3}, {image: vinos_4}, {image: vinos_5}, {image: vinos_6}]
const farmaceutica = [{image: farmaceutica_1}, {image: farmaceutica_2}, {image: farmaceutica_3}, {image: farmaceutica_4}, {image: farmaceutica_5}, {image: farmaceutica_6}]
const cuidado_personal = [{image: cuidado_1}, {image: cuidado_2}, {image: cuidado_3}, {image: cuidado_4}]


const Industria = () => (
    <div>
        <div className="uk-container-expand header">
            <MainMenu />
        </div>
        <div className="uk-container-expand uk-background-muted industriasHeader">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-padding" data-uk-grid></div>
            </div>
        </div>
        <div className="uk-container-expand uk-background-muted uk-box-shadow-large">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-padding-small uk-text-center" data-uk-grid>
                    <div>
                        <h3 className='uk-margin-remove h3-gray'>OFRECEMOS SOLUCIONES A LAS</h3>
                        <h1 className='uk-margin-remove h3-gray ffx-bold uk-text-uppercase'>NECESIDADES DE CADA INDUSTRIA</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="uk-container-expand">
            <div className='uk-container-expand'>
                <div className="uk-grid uk-child-width-1-1 uk-padding-small uk-margin-top uk-margin-large-bottom uk-text-center" data-uk-grid>
                    <div className="uk-width-expand">
                        <ul className="uk-flex-center" data-uk-switcher="connect: .switcher-container; animation: uk-animation-fade; toggle: > *">
                            <button className="uk-button uk-button-primary" type="button">Industrial y cuidado del hogar</button>
                            <button className="uk-button uk-button-primary uk-margin-left" type="button">Alimentos y bebidas</button>
                            <button className="uk-button uk-button-primary uk-margin-left" type="button">Automotr&iacute;z</button>
                            <button className="uk-button uk-button-primary uk-margin-left" type="button">Vinos y licores</button>
                            <button className="uk-button uk-button-primary uk-margin-left" type="button">Farmac&eacute;utica y Veterinaria</button>
                            <button className="uk-button uk-button-primary uk-margin-left" type="button">Cuidado Personal</button>
                        </ul>
                        <ul className="uk-switcher switcher-container uk-margin">
                            <li><div class="uk-flex uk-flex-center"><Galeria list={agroquimica}/></div></li>
                            <li><div class="uk-flex uk-flex-center"><Galeria list={alimentos}/></div></li>
                            <li><div class="uk-flex uk-flex-center"><Galeria list={automotriz}/></div></li>
                            <li><div class="uk-flex uk-flex-center"><Galeria list={vinos}/></div></li>
                            <li><div class="uk-flex uk-flex-center"><Galeria list={farmaceutica}/></div></li>
                            <li><div class="uk-flex uk-flex-center"><Galeria list={cuidado_personal}/></div></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
);

function Galeria(props) {
    const items = props.list.map((item, i) =>
        <span key={i}>
            <img src={item.image} width="248" alt=""/>
        </span>
    )
    return items
}

export default Industria;