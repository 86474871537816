import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// Routing sections
// root menu
import Home from './Home'
import Nosotros from './Nosotros'
// Soluciones
import Etiquetas from './soluciones/Etiquetas'
import Empaques from './soluciones/Empaques'
import Mangas from './soluciones/Mangas'
import Boletos from './soluciones/Boletos'
// root menu
import Industrias from './Industrias'
import Tecnologia from './Tecnologia'
import Contacto from './Contacto'
// 404
import NotFound from './NotFound'

const Root = () => (
    <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/nosotros" element={<Nosotros/>}/>
          
          <Route exact path="/etiquetas-autoadheribles" element={<Etiquetas/>}/>
          <Route exact path="/empaques-flexibles" element={<Empaques/>}/>
          <Route exact path="/mangas-thermoencogibles" element={<Mangas/>}/>
          <Route exact path="/boletos-para-eventos" element={<Boletos/>}/>

          <Route exact path="/industrias" element={<Industrias/>}/>
          <Route exact path="/tecnologia" element={<Tecnologia/>}/>
          <Route exact path="/contacto" element={<Contacto/>}/>

          <Route path="*" element={<Home/>}/>
        </Routes>
    </Router>
);


export default Root;