import MainMenu from '../MainMenu'
import Footer from '../Footer'

import rollos from '../../images/soluciones/etiquetas/soluciones-proyectos-etiquetas.png'
/* icons */
import icon_1 from '../../images/soluciones/icons/soluciones-icono-materiales.png'
import icon_2 from '../../images/soluciones/icons/soluciones-icono-especialidad.png'
import icon_3 from '../../images/soluciones/icons/soluciones-icono-terminado.png'
import icon_4 from '../../images/soluciones/icons/soluciones-icono-tintas.png'
import icon_5 from '../../images/soluciones/icons/soluciones-icono-presentacion.png'
import icon_6 from '../../images/soluciones/icons/soluciones-icono-aplicaciones.png'
import icon_7 from '../../images/soluciones/icons/soluciones-icono-diseno.png'

const proyectos = [
    {icon: icon_1, title: 'Materiales', description: 'Papel (Couche blanco, metalizado, litho mate, pharmagloss, papel termico). Película (Bopp blanco, transparente, metalizada y void).'},
    {icon: icon_2, title: 'Especialidad', description: 'Hot stamping, cold foil y realzado.'},
    {icon: icon_3, title: 'Terminado', description: 'Hot stamping, cold foil y realzado.'},
    {icon: icon_4, title: 'Tintas', description: 'Selección a color, pantones directos, fluorescente de seguridad, base agua y uv.'},
    {icon: icon_5, title: 'Presentación', description: 'Rollo.'},
    {icon: icon_6, title: 'Aplicaciones', description: 'Cajas, botes pet, aluminio, metal, vidrio, bolsas y muchos más.'},
    {icon: icon_7, title: 'Departamento de Diseño', description: 'Contamos con personal altamente capacitado en diseño y pre-prensa para flexografía, digital y otros procesos complementarios.'},
]


const Etiquetas = () => (
    <div>
        <div className="uk-container-expand header">
            <MainMenu />
        </div>
        <div className="uk-container-expand uk-background-muted etiquetasHeader">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-padding" data-uk-grid>
                    <div>
                        <div className="uk-card uk-card-ffx-light uk-card-body uk-width-1-2">
                            <h3 className='uk-margin-remove ffx-heading'>ETIQUETAS</h3>
                            <h1 className='uk-margin-remove h3-gray ffx-bold'>AUTOADHERIBLES</h1>
                            <h3 className='uk-margin-remove h3-gray'>EN FLEXOGRAFÍA O DIGITAL</h3>
                            <hr />
                            <h3 className='uk-margin-remove ffx-heading uk-text-uppercase ffx-medium-text'>La etiqueta dice mucho de tus productos.</h3>
                            <p>Elaboramos etiquetas de alta calidad en flexografía y digital para todo tipo de productos. Contamos con gran variedad de materiales que se adaptan a las necesidades del cliente en la aplicación y etiquetado.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="uk-container-expand uk-padding-small">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-padding" data-uk-grid>
                    <div>
                        <h1 className='uk-margin-remove h3-gray ffx-bold uk-text-uppercase'>Proyectos</h1>
                        <hr />
                    </div>
                    <div className='uk-width-1-1 uk-margin-small-top'>
                        <div className="uk-child-width-expand@s uk-text-center" data-uk-grid>
                            <div>
                                <div className="uk-card uk-card-body"><img src={rollos} alt=""/></div>
                            </div>
                            <div>
                                <div className="uk-card uk-card-body">
                                    <Proyectos list={proyectos}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
);

function Proyectos(props) {
    const items = props.list.map((item, i) =>
        <div key={i}>
            <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                <div className="uk-width-auto">
                    <img src={item.icon} alt=""/>
                </div>
                <div className="uk-width-expand uk-text-justify uk-margin">
                    <h5 className="uk-text-uppercase uk-text-bold uk-margin-remove-bottom h3-gray">{item.title}</h5>
                    <p className="uk-text-meta uk-margin-remove-top">{item.description}</p>
                </div>
            </div>
        </div>
    )
    return items
}


export default Etiquetas;