import MainMenu from '../MainMenu'
import Footer from '../Footer'

import rollos from '../../images/soluciones/mangas/soluciones-proyectos-mangas.png'
/* icons */
import icon_1 from '../../images/soluciones/icons/soluciones-icono-materiales.png'
import icon_4 from '../../images/soluciones/icons/soluciones-icono-tintas.png'
import icon_5 from '../../images/soluciones/icons/soluciones-icono-presentacion.png'
import icon_6 from '../../images/soluciones/icons/soluciones-icono-aplicaciones.png'
import icon_7 from '../../images/soluciones/icons/soluciones-icono-diseno.png'

const proyectos = [
    {icon: icon_1, title: 'Materiales', description: 'PVC y PET-G.'},
    {icon: icon_4, title: 'Tintas', description: 'Tinta UV y agua.'},
    {icon: icon_5, title: 'Presentación', description: 'Rollo o piezas sueltas.'},
    {icon: icon_6, title: 'Aplicaciones', description: 'Envase de vidrio o plástico de cualquier tamaño.'},
    {icon: icon_7, title: 'Departamento de Diseño', description: 'Contamos con personal altamente capacitado en diseño y pre-prensa para flexografía, digital y otros procesos complementarios.'},
]


const Mangas = () => (
    <div>
        <div className="uk-container-expand header">
            <MainMenu />
        </div>
        <div className="uk-container-expand uk-background-muted mangasHeader">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-padding" data-uk-grid>
                    <div>
                        <div className="uk-card uk-card-ffx-light uk-card-body uk-width-1-2">
                            <h3 className='uk-margin-remove ffx-heading'>MANGAS</h3>
                            <h1 className='uk-margin-remove h3-gray ffx-bold'>THERMOENCOGIBLES</h1>
                            <h3 className='uk-margin-remove h3-gray'>EN FLEXOGRAFÍA O DIGITAL</h3>
                            <hr />
                            <h3 className='uk-margin-remove ffx-heading uk-text-uppercase ffx-medium-text'>Viste la imagen de tu producto a 360º</h3>
                            <p>Son elaboradas en película de formulación especial y se encogen por medio de calor hasta que tomen la forma del envase. Son las más utilizadas en el mercado por su gran adaptación a las superficies más difíciles o irregulares. En flexografía o digital.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="uk-container-expand uk-padding-small">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-padding" data-uk-grid>
                    <div>
                        <h1 className='uk-margin-remove h3-gray ffx-bold uk-text-uppercase'>Proyectos</h1>
                        <hr />
                    </div>
                    <div className='uk-width-1-1 uk-margin-small-top'>
                        <div className="uk-child-width-expand@s uk-text-center" data-uk-grid>
                            <div>
                                <div className="uk-card uk-card-body"><img src={rollos}/></div>
                            </div>
                            <div>
                                <div className="uk-card uk-card-body">
                                    <Proyectos list={proyectos}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
);

function Proyectos(props) {
    const items = props.list.map((item, i) =>
        <div key={i}>
            <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                <div className="uk-width-auto">
                    <img src={item.icon} />
                </div>
                <div className="uk-width-expand uk-text-justify uk-margin">
                    <h5 className="uk-text-uppercase uk-text-bold uk-margin-remove-bottom h3-gray">{item.title}</h5>
                    <p className="uk-text-meta uk-margin-remove-top">{item.description}</p>
                </div>
            </div>
        </div>
    )
    return items
}


export default Mangas;