import React, { Component } from 'react'
import { Link } from 'react-scroll'

import formflex_logo_w from '../images/logo.png'
import phone from '../images/icono-telefono-header.png'


class MainMenu extends Component {

    render() {
        let url = ""
        return (
            <div>                
                <div className='uk-container uk-padding-small'>
                    <nav className="uk-navbar-container uk-margin uk-navbar-transparent uk-light" data-uk-navbar>
                        <div className="uk-navbar-left">
                            <a className="uk-navbar-item uk-logo" href="#"><img src={formflex_logo_w} alt=""/></a>
                        </div>
                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav">
                                <li>
                                    <a href="/">Inicio</a>
                                </li>
                                <li><a href="/nosotros">Nosotros</a></li>
                                <li>
                                    <a href="#">Soluciones</a>
                                    <div className="uk-navbar-dropdown uk-text-left ffx-navbar-dropdown" uk-dropdown="animation: uk-animation-slide-top-small; duration: 250; offset: -20;">
                                        <ul className="uk-nav uk-navbar-dropdown-nav uk-text-uppercase">
                                            <li><a href="/etiquetas-autoadheribles">Etiquetas autoadheribles</a></li>
                                            <li><a href="/empaques-flexibles">Empaques flexibles</a></li>
                                            <li><a href="/mangas-thermoencogibles">Mangas thermoencogibles</a></li>
                                            <li><a href="/boletos-para-eventos">Boletos para eventos</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li><a href="/industrias">Industrias</a></li>
                                <li><a href="/tecnologia">Tecnolog&iacute;a</a></li>
                                <li><a href="/contacto">Contacto</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

export default MainMenu;