import MainMenu from './MainMenu'
import Footer from './Footer'

import tecnologia from '../images/tecnologia/tecnologia-productos.png'
//icons
import maquinas from '../images/tecnologia/icono-maquinas-tecnologia.png'
import tintas from '../images/tecnologia/icono-tintas-tecnologia.png'
import terminados from '../images/tecnologia/icono-terminados-tecnologia.png'
import revisadoras from '../images/tecnologia/icono-revisadoras-tecnologia.png'
import sustratos from '../images/tecnologia/icono-sustratos-tecnologia.png'

const Tecnologia = () => (
    <div>
        <div className="uk-container-expand header">
            <MainMenu />
        </div>
        <div className="uk-container-expand uk-background-muted tecnologiaHeader"></div>
        <div className="uk-container-expand uk-margin-remove uk-padding-remove uk-background-muted">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-margin-top" data-uk-grid>
                    <div className="uk-width-1-3 uk-text-center">
                        <img src={tecnologia} data-uk-responsive alt=""/>
                    </div>
                    <div className="uk-margin-auto uk-margin-auto-vertical">
                        <h3 className='uk-margin-remove h3-gray ffx-heading'>CONOCE NUESTROS PROCESOS DE</h3>
                        <h1 className='uk-margin-remove ffx-heading ffx-bold'>CALIDAD CON TECNOLOGÍA DE PUNTA</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="uk-container-expand uk-background-muted uk-padding">
            <div className='uk-container uk-padding-small'>
                <div className="u" data-uk-grid>
                    <div className="uk-width-1-5@m">
                        <div>
                            <ul className="uk-nav uk-nav-default uk-text-center">
                                <li className="uk-nav-header"><img src={maquinas} alt=""/></li>
                                <li className="uk-nav-header uk-text-bold">M&aacute;quinas</li>
                                <hr />
                                <li className='uk-text-left'>5 máquinas de 8 y 9 tintas hasta 13"</li>
                                <li className='uk-text-left'>1 máquina digital HP 6800</li>
                            </ul>
                        </div>
                    </div>
                    <div className="uk-width-1-5@m">
                        <div>
                            <ul className="uk-nav uk-nav-default uk-text-center">
                                <li className="uk-nav-header"><img src={tintas} alt=""/></li>
                                <li className="uk-nav-header uk-text-bold">TINTAS</li>
                                <hr />
                                <li className='uk-text-left'>Base UV</li>
                                <li className='uk-text-left'>Base agua</li>
                                <li className='uk-text-left'>Electroink (digital)</li>
                            </ul>
                        </div>
                    </div>
                    <div className="uk-width-1-5@m">
                        <div>
                            <ul className="uk-nav uk-nav-default uk-text-center">
                                <li className="uk-nav-header"><img src={terminados} alt=""/></li>
                                <li className="uk-nav-header uk-text-bold">TERMINADOS</li>
                                <hr />
                                <li className='uk-text-left'>Barniz brillante UV</li>
                                <li className='uk-text-left'>Barniz mate UV</li>
                                <li className='uk-text-left'>Barniz base agua</li>
                                <li className='uk-text-left'>Laminado UV</li>
                                <li className='uk-text-left'>Laminado autoadherible brillante y mate</li>
                                <li className='uk-text-left'>Laminado autoadherible mate</li>
                                <li className='uk-text-left'>Embozado (realzado)</li>
                                <li className='uk-text-left'>Cold foil</li>
                                <li className='uk-text-left'>Hot foil (hot stamping)</li>
                            </ul>
                        </div>
                    </div>
                    <div className="uk-width-1-5@m">
                        <div>
                            <ul className="uk-nav uk-nav-default uk-text-center">
                                <li className="uk-nav-header"><img src={revisadoras} alt=""/></li>
                                <li className="uk-nav-header uk-text-bold">REVISADORAS</li>
                                <hr />
                                <li className='uk-text-left'>Tenemos 3 máquinas revisadoras Rotoflex de 330mm.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="uk-width-1-5@m">
                        <div>
                            <ul className="uk-nav uk-nav-default uk-text-center">
                                <li className="uk-nav-header"><img src={sustratos} alt=""/></li>
                                <li className="uk-nav-header uk-text-bold">SUSTRATOS</li>
                                <hr />
                                <li className='uk-text-left'>Couché</li>
                                <li className='uk-text-left'>Couché Metalizado</li>
                                <li className='uk-text-left'>Couché sin Adhesivo</li>
                                <li className='uk-text-left'>BOPP</li>
                                <li className='uk-text-left'>PVC</li>
                                <li className='uk-text-left'>PET-G</li>
                                <li className='uk-text-left'>Cartulina térmica</li>
                                <li className='uk-text-left'>Cartulina Doble Cara</li>
                                <li className='uk-text-left'>Empaque Flexible</li>
                                <li className='uk-text-left'>Glasspolifoil y Petpolifoil</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
);

export default Tecnologia;