import React from 'react'
import { Link } from 'react-scroll'


import playButton from '../images/boton-play.png'
import formflex_logo from '../images/logo-top-index.png'
import maquinas from '../images/rotativa-banner-top-index.png'
import productos from '../images/productos-banner-top-index.png'
/* switcher pics */
import switcher_1 from '../images/etiquetas-tabs-index.png'
import switcher_2 from '../images/empaques-tabs-index.png'
import switcher_3 from '../images/mangas-tabs-index.png'
import switcher_4 from '../images/boletos-tabs-index.png'
/* icons */
import icon_1 from '../images/icons/farmaceutica-y-veterinaria-index.png'
import icon_2 from '../images/icons/entretenimiento-index.png'
import icon_3 from '../images/icons/cosmetica-index.png'
import icon_4 from '../images/icons/vinos-y-licores-index.png'
import icon_5 from '../images/icons/alimentos-y-bebidas-index.png'
import icon_6 from '../images/icons/industrial-index.png'
import icon_7 from '../images/icons/automotriz-index.png'

import sectionBG from '../images/background-tec-index.jpg'

import MainMenu from './MainMenu'
import Footer from './Footer'


const Home = () => (
    <div>
        <SlideShow />
        <ModalVideo />
    </div>
);

const SlideShow = () => (
    <div id='home_start'>
        <div className="uk-position-relative">
            <div className="content">
                <video id="player" src='https://tinyurl.com/2z34q3y7' autoPlay={true} loop muted></video>
            </div>
            <div className="uk-position-top uk-margin-remove uk-text-center">
                <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar id="headerLogo">
                    <div className="uk-navbar-center uk-text-center">
                        <div className="uk-card uk-card-body adjust">
                            <p>REALIZAMOS TRABAJOS A TODA LA REPÚBLICA MEXICANA, SOLICITA UNA COTIZACIÓN AL 33 3777 1632</p>
                            <img src={formflex_logo} alt="ffx_logo"/>
                        </div>
                    </div>
                </nav>
                <div className="uk-card uk-light uk-card-body uk-width-1-2@m uk-align-center uk-margin-large-top">
                    <h2 className="titles h2">SOLUCIONES DE IMPRESI&Oacute;N EN FLEXOGRAF&Iacute;A Y DIGITAL</h2>
                    <h3 className='titles h3'>ETIQUETA AUTOADHERIBLE, EMPAQUE FLEXIBLE MANGA THERMOENCOGIBLE Y BOLETOS</h3>
                    <img src={playButton} width="52" alt="playButton" uk-toggle="target: #modal-center"/>
                    <div className='jump uk-text-uppercase uk-margin-medium-top'>
                        <Link
                            activeclassname="uk-active"
                            to="header"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}>
                            <p>Desliza hacia abajo</p>
                            <span uk-icon="icon: chevron-down; ratio: 2"></span>
                        </Link>
                    </div>
                </div>
                <div className="uk-margin-large-top foot-top-index">
                    <p className="uk-margin-small-top">&copy; FORMFLEX | Soluciones de impresi&oacute;n en flexograf&iacute;a y digital | 2022</p>
                </div>
                <div className='uk-height-xlarge header' id="header">
                    <MainMenu />
                    <div className='uk-container-expand banner-index'>
                        <div className="uk-container uk-padding">
                            <div className="uk-grid-small uk-child-width-expand@s uk-text-center" data-uk-grid>
                                <div>
                                    <div className="uk-card uk-card-body uk-margin-large-top"><img src={maquinas} className='maquinas_home' alt=""/></div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-body uk-text-left uk-light">
                                        <h2 className='uk-margin-remove'>SOLUCIONES DE IMPRESI&Oacute;N</h2>
                                        <h1 className='uk-margin-remove uk-text-italic'>EN FLEXOGRAF&Iacute;A Y DIGITAL</h1>
                                        <img src={productos} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Switcher />
                <Asesoria />
                <Procesos />
                <Footer />
            </div>
        </div>
    </div>
);

const Switcher = () => (
    <div className='uk-container-expand'>
        <div className="uk-container">
            <div className='uk-text-left uk-margin-large-bottom'>
                <h4 className='uk-margin-remove'>SOLUCIONES DE IMPRESI&Oacute;N</h4>
                <h3 className='uk-margin-remove uk-text-italic'>EN FLEXOGRAF&Iacute;A Y DIGITAL</h3>
            </div>
            <div>
                <div data-uk-grid>
                    <div className="uk-width-1-4">
                        <ul className="uk-tab-left switcher_menu" uk-tab="connect: #component-tab-left; animation: uk-animation-fade">
                            <li><a href="#">Etiquetas autoadheribles</a></li>
                            <li><a href="#">Empaques flexibles</a></li>
                            <li><a href="#">Mangas thermoencogibles</a></li>
                            <li><a href="#">Boletos para eventos</a></li>
                        </ul>
                    </div>
                    <div className="uk-width-3-4">
                        <ul id="component-tab-left" className="uk-switcher uk-text-left">
                            <li>
                                <div className="uk-grid-collapse uk-child-width-expand@s" data-uk-grid>
                                    <div>
                                        <div><img src={switcher_1} alt=""/></div>
                                    </div>
                                    <div>
                                        <div>
                                            <h4>Etiquetas Autoadheribles</h4>
                                            <p className='uk-text-justify'>Elaboramos etiquetas de alta calidad en flexografía y digital para todo tipo de productos. Contamos con gran variedad de materiales que se adaptan a las necesidades del cliente en la aplicación y etiquetado.</p>
                                            <div className='uk-align-right'>
                                                <a href="/etiquetas-autoadheribles" className="uk-button uk-button-primary-ffx">Conoce m&aacute;s</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="uk-grid-collapse uk-child-width-expand@s" data-uk-grid>
                                    <div>
                                        <div><img src={switcher_2} alt=""/></div>
                                    </div>
                                    <div>
                                        <div>
                                            <h4>Empaques flexibles</h4>
                                            <p className='uk-text-justify'>Los empaques flexibles son ligeros y de fácil manejo. Son ideales para el uso de sobres pouch, sachet y flow pack. El empaque flexible es comúnmente aplicado en el envasado de polvos, líquidos y muchos más.</p>
                                            <div className='uk-align-right'>
                                                <a href="/empaques-flexibles" className="uk-button uk-button-primary-ffx">Conoce m&aacute;s</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="uk-grid-collapse uk-child-width-expand@s" data-uk-grid>
                                    <div>
                                        <div><img src={switcher_3} alt=""/></div>
                                    </div>
                                    <div>
                                        <div>
                                            <h4>Mangas thermoencogibles</h4>
                                            <p className='uk-text-justify'>Son elaboradas en película de formulación especial y se encogen por medio de calor hasta que tomen la forma del envase. Son las más utilizadas en el mercado por su gran adaptación a las superficies más difíciles o irregulares. En impresión flexográfica o digital.</p>
                                            <div className='uk-align-right'>
                                                <a href="/mangas-thermoencogibles" className="uk-button uk-button-primary-ffx">Conoce m&aacute;s</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="uk-grid-collapse uk-child-width-expand@s" data-uk-grid>
                                    <div>
                                        <div><img src={switcher_4} alt=""/></div>
                                    </div>
                                    <div>
                                        <div>
                                            <h4>Boletos para eventos</h4>
                                            <p className='uk-text-justify'>Tenemos formatos de mediano a pequeño tamaño, se imprimen o realizan con una función específica. Imprimimos boletos, cupones, pases y tickets con diseños y tamaños diversos que se adaptan a las necesidades de nuestros clientes. En impresión flexográfica o digital.</p>
                                            <div className='uk-align-right'>
                                                <a href="/boletos-para-eventos" className="uk-button uk-button-primary-ffx">Conoce m&aacute;s</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const Asesoria = () => (
    <div className='uk-container-expand uk-background-muted uk-padding'>
        <div className="uk-container">
            <div data-uk-grid>
                <div className="uk-width-1-3">
                    <div className='asesor'>
                        <div className='sample'>
                            <h3 className='uk-margin-remove ffx-heading h3'>SOLUCIONES A LAS</h3>
                            <h1 className='uk-margin-remove ffx-heading h1'>NECESIDADES DE</h1>
                            <h1 className='uk-margin-remove ffx-heading h1'>TU INDUSTRIA</h1>
                        </div>
                    </div>
                </div>
                <div className="uk-width-2-3 uk-text-left desc">
                    <h3 className='uk-margin-remove'>ASESORÍA PERSONALIZADA</h3>
                    <p className='uk-margin-remove'>Te brindamos atención a la medida de tus necesidades , contamos con un equipo de especialistas que te ayudarán a desarrollar tu proyecto.</p>
                    <h3 >INDUSTRIAS</h3>
                    <div className="uk-grid-collapse uk-child-width-1-2@s uk-margin-remove" data-uk-grid>
                        <div>
                            <div><img src={icon_1}  alt=""/>FARMACÉUTICA Y VETERINARIA</div>
                        </div>
                        <div>
                            <div><img src={icon_2}  alt=""/>ENTRETENIMIENTO</div>
                        </div>
                        <div>
                            <div><img src={icon_3} alt=""/>COSMÉTICA</div>
                        </div>
                        <div>
                            <div><img src={icon_4} alt=""/>VINOS Y LICORES</div>
                        </div>
                        <div>
                            <div><img src={icon_5} alt=""/>ALIMENTOS Y BEBIDAS</div>
                        </div>
                        <div>
                            <div><img src={icon_6} alt=""/>INDUSTRIAL Y CUIDADO DEL HOGAR</div>
                        </div>
                        <div>
                            <div><img src={icon_7} alt=""/>AUTOMOTRIZ</div>
                        </div>
                        <div className="uk-text-right uk-margin-top">
                            <a href="/industrias" className="uk-button uk-button-primary-ffx">Ver proyectos</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const Procesos = () => (
    <div className="uk-height-medium uk-background-cover uk-light uk-flex" data-uk-parallax="x: 0,50 10%,150 50%" style={{ backgroundImage: `url(${sectionBG})` }}>
        <div className="uk-width-1-2 uk-margin-auto uk-margin-auto-vertical">
            <h3 className='uk-margin-remove ffx-heading'>CONOCE NUESTROS PROCESOS DE CALIDAD</h3>
            <h1 className='uk-margin-remove h3-gray ffx-bold'>CON TECNOLOGÍA DE PUNTA</h1>
            <p className='uk-margin-remove h3-gray'>En Formflex nos aseguramos de entregar productos de la mejor calidad mediante el uso de los mejores materiales y la implementación de las tecnologías más actuales en todos los procesos que llevamos a cabo.</p>
            <div className='uk-margin'>
                <a href="/tecnologia" className="uk-button uk-button-primary-ffx">Materiales y tecnolog&iacute;a</a>
            </div>
        </div>
    </div>
);

const ModalVideo = () => (
    <div id="modal-center" className="uk-flex-top" data-uk-modal>
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
            <iframe src="https://www.youtube.com/embed/Z5GELEGxpAs" width="800" height="486" allowFullScreen data-uk-responsive uk-video="automute: true"></iframe>
        </div>
    </div>
);

export default Home;