import MainMenu from '../MainMenu'
import Footer from '../Footer'

import rollos from '../../images/soluciones/boletos/soluciones-proyectos-boletos.png'
/* icons */
import icon_1 from '../../images/soluciones/icons/soluciones-icono-materiales.png'
import icon_2 from '../../images/soluciones/icons/soluciones-icono-especialidad.png'
import icon_3 from '../../images/soluciones/icons/soluciones-icono-terminado.png'
import icon_4 from '../../images/soluciones/icons/soluciones-icono-tintas.png'
import icon_5 from '../../images/soluciones/icons/soluciones-icono-presentacion.png'
import icon_7 from '../../images/soluciones/icons/soluciones-icono-diseno.png'

const proyectos = [
    {icon: icon_1, title: 'Materiales', description: 'Cartulina térmica y couché 2 caras.'},
    {icon: icon_2, title: 'Especialidad', description: 'Holograma personalizado, linea FORCOGSA, folio, información variable y relieve.'},
    {icon: icon_3, title: 'Terminado', description: 'Barniz brillante, mate y sin acabado.'},
    {icon: icon_4, title: 'Tintas', description: 'Tinta UV y agua.'},
    {icon: icon_5, title: 'Presentación', description: 'Fanfold y blocks..'},
    {icon: icon_7, title: 'Departamento de Diseño', description: 'Contamos con personal altamente capacitado en diseño y pre-prensa para flexografía, digital y otros procesos complementarios.'},
]


const Boletos = () => (
    <div>
        <div className="uk-container-expand header">
            <MainMenu />
        </div>
        <div className="uk-container-expand uk-background-muted boletosHeader">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-padding" data-uk-grid>
                    <div>
                        <div className="uk-card uk-card-ffx-light uk-card-body uk-width-1-2">
                            <h3 className='uk-margin-remove ffx-heading'>BOLETOS</h3>
                            <h1 className='uk-margin-remove h3-gray ffx-bold'>PARA EVENTOS</h1>
                            <hr />
                            <h3 className='uk-margin-remove ffx-heading uk-text-uppercase ffx-medium-text'>Con hologramas de seguridad 100% confiables</h3>
                            <p>Tenemos formatos de mediano a pequeño tamaño, se imprimen o realizan con una función específica. Imprimimos boletos, cupones, pases y tickets con diseños y tamaños diversos que se adaptan a las necesidades de nuestros clientes. En flexografía o digital.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="uk-container-expand uk-padding-small">
            <div className='uk-container'>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-padding" data-uk-grid>
                    <div>
                        <h1 className='uk-margin-remove h3-gray ffx-bold uk-text-uppercase'>Proyectos</h1>
                        <hr />
                    </div>
                    <div className='uk-width-1-1 uk-margin-small-top'>
                        <div className="uk-child-width-expand@s uk-text-center" data-uk-grid>
                            <div>
                                <div className="uk-card uk-card-body"><img src={rollos} alt=""/></div>
                            </div>
                            <div>
                                <div className="uk-card uk-card-body">
                                    <Proyectos list={proyectos}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
);

function Proyectos(props) {
    const items = props.list.map((item, i) =>
        <div key={i}>
            <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                <div className="uk-width-auto">
                    <img src={item.icon}  alt=""/>
                </div>
                <div className="uk-width-expand uk-text-justify uk-margin">
                    <h5 className="uk-text-uppercase uk-text-bold uk-margin-remove-bottom h3-gray">{item.title}</h5>
                    <p className="uk-text-meta uk-margin-remove-top">{item.description}</p>
                </div>
            </div>
        </div>
    )
    return items
}


export default Boletos;