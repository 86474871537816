import './App.css'
import './media-queries.css'
import Root from './components/Root'

function App() {
  return (
    <div className="App">
      <Root />
    </div>
  );
}

export default App;